.p-datatable-thead {
  background-color: $white;
}

.p-datatable-thead tr th {
  padding: .5rem .75rem;
  text-transform: uppercase;
  color: $primary;
  background-color: $white;
}

.p-datatable-thead tr th .p-icon-wrapper {
  float: right;
  padding: .2rem;
}

.p-datatable-thead tr th {
  font-size: .875rem;
}

.p-datatable-tbody tr td {
  font-size: .875rem;
  padding: .75rem;
  line-height: 1.25rem;
}

.p-datatable-tbody tr:nth-child(odd),
.p-datatable-tbody tr:nth-child(even) {
  &:hover {
    background-color: $gray-light;
  }
}

.p-datatable-tbody tr:nth-child(odd) {
  background-color: $whitish;
}

.p-datatable-thead {
  border-bottom: 1px solid $gray;
}
