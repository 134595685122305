// Colors start
$transparent: transparent;

$black-transparent: #000000CC;
$black: #333;
$black-darker: #282828;
$black-dark: #161616;
$black-lighter: #666666;
$white: #fff;
$whitish: #F2F4F4;

$primary: #1C7F9D;
$primary-light: #18A3CB;
$primary-lighter: #7dcffa;
$primary-lightest: #6cbfe9;
$primary-pale: #a2defc;
$primary-dark: #386979;

$secondary: #486169;
$secondary-alt: #546A71;
$secondary-lighter: #7B959D;
$secondary-light: #A7B8BE;
$secondary-lightest: #E2EEF2;

$gray-dark: #adb8c1;
$gray: #BECBD0;
$gray-light: #E2EEF2;
$gray-light-alt: #E4ECEF;
$gray-lightest: #EDF5F7;

$red: #FF4B4B;
$red-lighter: #FF9999;
$red-light: #FFCBCB;
$red-dark: #E50000;

$yellow-lighter: #ffdd00;
$yellow: #FFE667;
$yellow-dark: #7C6C17;

$orange-light: #ffba61;
$orange-dark: #785105;

$green: #71EE52;
$green-dark: #335A2A;
$green-light: #ACFF97;
// Colors end


