.p-datepicker table td > span.p-highlight {
  background-color: #1C7F9D;
  color: white;
}

.p-datepicker table td.p-datepicker-today > span {
  background-color: #18A3CB;
  color: white;
}

.p-datepicker-title * {
  margin-left: .2rem;
  margin-right: .2rem;
}

.dateselect .p-inputtext {
  border: 0;
  text-align: center;
}

.dateselect .p-inputtext:enabled:hover {
  box-shadow: none;
  background-color: $gray;
}

.dateselect .p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-datepicker-trigger {
  border-radius: 0 .5rem .5rem 0;
}
