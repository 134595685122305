.p-dropdown {
  padding: 0;
}

.p-inputwrapper.p-dropdown {
  border: 1px solid $gray;
}

.dropdownwrapper:focus {
  box-shadow: 0 0 0 .5rem $primary-pale;
}

.p-inputtext.p-dropdown-label {
  padding: .75rem;
  line-height: 1.25rem;
  font-size: .9rem;
  border: 0;
  // border-radius: 0;
  margin-right: -1px;
  min-width: 8rem;
}

.p-dropdown-clear-icon {
  margin-right: 1rem;
  margin-left: 3rem;
  display: block;
}

.p-icon-wrapper {
  padding: 0 2rem;
}

.p-dropdown-filter-container .p-icon-wrapper {
  padding: 0;
}

.p-dropdown-item,
.p-menuitem {
  font-size: .9rem;
  font-weight: 500;
}

.p-dropdown-item.p-highlight {
  background-color: $secondary-lightest;
  color: $primary;
}
