.p-inputtext {
  padding: .75rem;
  font-size: .9rem;
  line-height: 1.25rem;
  border: 1px solid $gray;
}

.p-dropdown-trigger {
  border-left: 1px solid $gray;
}

.p-input-icon-left .p-inputtext {
  padding-left: 2.25rem;
}


// Outline
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #E4ECEF;
  border-color: #1C7F9D;
}
