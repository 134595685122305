.p-dialog {
  box-shadow: none;
  min-width: 40rem;
}

.p-dialog-header {
  border-bottom: 1px solid $gray;
  padding: 1rem;
  color: $secondary;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.p-dialog-header-icon {
  color: $black-lighter;
  padding: .5rem 1.25rem;
  border-radius: .375rem;

  &:hover {
    background-color: $gray-light;
  }

  &:active {
    background-color: $gray-dark;
  }
}

.p-dialog-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: $secondary;
}

.p-dialog-content {
  padding: 0;
}

.gn-modal-content {
  padding: 1.25rem;
  font-weight: 400;
  font-size: 1rem;
}

.gn-modal-footer > div {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $gray;
  padding: 1.25rem;
}
